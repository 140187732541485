@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1 {
    font-family: poppins,sans-serif;
}

p {
    font-family: raleway,sans-serif;
}

.bg-cool-gradient {
    background-image:linear-gradient(to bottom right,#101010 40%,#202020);
}

.gradient {
    -webkit-background-clip:text;
    background-clip:text;
    background-image:linear-gradient(to left,#fc2948,#9b0356);
    color:transparent;
}